import React from 'react'

import PageTitle from '@/components/PageTitle'

import './RollDiceStyles.less'

const diceList = [4, 6, 8, 10, 12, 20, 100, 1000]

const RollDiceComponent = ({ rollList, onRoll }) => (
  <section className='RollDice'>
    <PageTitle>Броски кубиков</PageTitle>

    <p className='RollDice_result'>
      {
        rollList.length
          ? rollList.map(
          (roll, i) => (
            <span
              className='RollDice_roll'
              key={`${i}_${roll}`}
            >
                  {roll}
                </span>
          ),
          )
          : '?'
      }
    </p>

    <ul className='RollDice_list'>
      {
        diceList.map(
          diceType => (
            <li
              key={diceType}
              className='RollDice_item'
            >
              <button
                className='RollDice_btn'
                onClick={onRoll(diceType)}
              >
                {diceType}
              </button>
            </li>
          ),
        )
      }
    </ul>
  </section>
)

export default RollDiceComponent
