import React from 'react'

import AdsAtGenerator from '@/ads/components/AdsAtGenerator'
import Layout from '@/components/Layout'
import Seo from '@/components/Seo'
import RollDice from '@/components/RollDice'

export default () => (
  <Layout className='Roll'>
    <Seo
      title='Броски кубиков'
    />
    <AdsAtGenerator/>
    <RollDice/>
  </Layout>
)
