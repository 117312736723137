import React, { Component } from 'react'

import rollDice from '@/utils/rollDice'
import trackAnalyticEvent from '@/utils/trackAnalyticEvent'

import RollDiceComponent from './RollDiceComponent'

const MAX_ROLL_SAVE_QUANTITY = 5

class RollDiceContainer extends Component {
  state = {
    rollList: [],
  }

  onRoll = diceType => () => {
    const {rollList} = this.state
    const result = rollDice(diceType)() + 1

    rollList.unshift(result)

    this.setState({rollList: rollList.slice(0, MAX_ROLL_SAVE_QUANTITY)})

    trackAnalyticEvent('roll-dice')
  }

  render() {
    const {rollList} = this.state

    return (
      <RollDiceComponent
        rollList={rollList}
        onRoll={this.onRoll}
      />
    )
  }
}

export default RollDiceContainer
